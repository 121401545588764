<template>
    <validation-observer ref="reportForm">
        <b-form>
            <b-modal id="bv-modal-report" centered hide-footer :title="$t('g.reportTitle')">
                <!-- Start Reason Input -->
                <b-form-group label-for="reason" class="mb-2">
                    <validation-provider #default="{ errors }" name="reason" rules="required">
                        <b-form-select :options="options" v-model="selected" />
                        <small class="text-danger">{{
                            errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                        }}</small>
                        <svg class="select-arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                            viewBox="0 0 20 20" fill="none">
                            <path d="M15.8346 7.5L10.0013 13.3333L4.16797 7.5" stroke="#5E5E5E" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </validation-provider>
                </b-form-group>
                <!-- End Reason Input -->

                <!-- Start Message Input -->
                <b-form-group label-for="message" class="mb-4">
                    <validation-provider #default="{ errors }" name="message" rules="required">
                        <b-form-textarea placeholder="Message" v-model="reaportMessage" rows="5" max-rows="10" />
                        <small class="text-danger">{{
                            errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                        }}</small>
                    </validation-provider>
                </b-form-group>
                <!-- End Message Input -->

                <!-- Start Form Actions Buttons -->
                <b-row align-h="end" class="report-footer m-0 mt-5">
                    <b-button class="mr-1" variant="outline-primary" @click="$bvModal.hide('bv-modal-report')">{{
                        $t('g.cancel') }}</b-button>
                    <b-button :disabled="is_loading" variant="primary" type="submit" @click.prevent="validation">{{
                        $t('g.Send')
                    }}</b-button>
                </b-row>
                <!-- Start Form Actions Buttons -->
                <overlay-component :isLoading="is_loading" />
            </b-modal>
        </b-form>
    </validation-observer>
</template>

<script>
import { required } from "@validations";
import OverlayComponent from '@/components/shared/OverlayComponent'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BModal, BButton, BForm, BFormSelect, BFormTextarea, BRow, BFormGroup } from 'bootstrap-vue'
export default {
    components: {
        BModal,
        BButton,
        BForm,
        BFormSelect,
        BFormTextarea,
        BRow,
        BFormGroup,
        OverlayComponent,
        ValidationObserver,
        ValidationProvider
    },
    props: ['reportableId', 'reportableType'],
    data() {
        return {
            required: required,
            is_loading: true,
            reaportMessage: null,
            selected: null,
            options: [
                { value: null, text: 'Reasons' },
            ],
        }
    },
    created() {
        this.getReportsType()
    },
    methods: {
        makeToast(variant = null, body, title) {
            this.$bvToast.toast(this.$t(body), {
                title: this.$t(title),
                variant,
                solid: true,
            });
        },
        async getReportsType() {
            try {
                this.is_loading = true
                const res = await this.$http.get('/admin/report-reasons')
                for (const reason of res.data.data) {
                    this.options.push({
                        value: reason.id,
                        text: reason.name,
                    })
                }
            } catch (error) {
                for (const key in error.response?.data?.errors) {
                    this.makeToast(
                        "danger",
                        error.response.data.errors[key][0],
                        this.$t("g.send.errorTitle")
                    );
                }
            } finally {
                this.is_loading = false
            }
        },
        async sendReaport() {
            try {
                this.is_loading = true
                const formData = new FormData()
                formData.append('report_reason_id', this.selected)
                formData.append('message', this.reaportMessage)
                formData.append('reportable_id', this.reportableId)
                formData.append('reportable_type', this.reportableType)
                await this.$http.post('/admin/reports', formData)
                this.makeToast(
                    "success",
                    this.$t("g.send.successBody"),
                    this.$t("g.send.successTitle")
                );
                this.selected = null
                this.reaportMessage = null
                this.$bvModal.hide('bv-modal-report')
            } catch (error) {
                showError(error, 'components/shared/report_component/can_not_send_report')
            } finally {
                this.is_loading = false
            }
        },
        validation() {
            this.$refs.reportForm.validate().then((success) => success && this.sendReaport())
        },
        showError(error, defaultError) {
            if (error.response?.data?.errors) {
                for (const key in error.response?.data?.errors) {
                    this.makeToast(
                        "danger",
                        error.response.data.errors[key][0],
                        this.$t("g.send.errorTitle")
                    );
                }
            } else if (error.response?.data?.message) {
                this.makeToast(
                    "danger",
                    error.response?.data?.message,
                    this.$t("g.send.errorTitle")
                );
            } else {
                this.makeToast(
                    "danger",
                    this.$t(`g.${defaultError}`),
                    this.$t("g.send.errorTitle")
                );
            }
        },
    },
}
</script>

<style lang="scss">
@import './index.scss';
</style>
<template>
    <b-card class="students-card" :title="$t('g.Students')">
        <hr />
        <div v-if="studentsCardProps.length">
            <b-row class="align-items-center mb-1" v-for="(student, index) in studentsCardProps" :key="index">
                <b-link @click="redirectUser(student.user.user_type, student.user.type_id, student.user.email)">
                    <b-avatar :src="filterImages(student.user.media, 'path')" class="mx-1" />
                    <span class="user-name">{{ `${student.user.first_name} ${student.user.last_name}` }}</span>
                </b-link>
            </b-row>
        </div>
        <h4 v-else>
            {{ $t('g.profile/students_card_component/no_students') }}
        </h4>
    </b-card>
</template>

<script>
import { BCard, BRow, BAvatar, BLink } from 'bootstrap-vue'
export default {
    name: 'ProfileFriendsCardComponent',
    components: {
        BRow,
        BCard,
        BAvatar,
        BLink
    },
    props: [
        'studentsCardProps'
    ],
    data() {
        return {
            userEmail: null,
            profileImgaePlaceholder: require('@/assets/images/placeholders/profileAvatar.png'),
        }
    },
    created() {
        const userData = JSON.parse(localStorage.getItem('userData'))
        this.userEmail = userData.email
    },
    methods: {
        redirectUser(userType, id, email) {
            if (email === this.userEmail) {
                this.$router.push('/profile')
            } else if (userType === 'student') {
                this.$router.push(`/show-student/${id}`)
            }
        },
        filterImages(data, name) {
            const placeholderImage = name === 'path' ? this.profileImgaePlaceholder : this.profileCoverPlaceholder
            if (data) {
                for (let image of data) {
                    if (image.name === name) {
                        return image.path
                    }
                }
            } else {
                return placeholderImage
            }
            return placeholderImage
        }
    }
}
</script>

<style lang="scss">
@import './index.scss';
</style>